



















































































import { AvailableField } from "@/models/available-field.model";
import {
  FilterOperation,
  Operator,
  UserFilter,
} from "@/models/user-filter.model";
import UserFilterService from "@/services/UserFilterService";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import UserFilterUsers from "./UserFilterUsers.vue";
import CodeEditor from "@/components/CodeEditor.vue";
import WorkshopSelect from "../workshops/WorkshopSelect.vue";

const userFiltersNamespace = namespace("UserFilters");

@Component({ components: { UserFilterUsers, CodeEditor, WorkshopSelect } })
export default class UserFiltersDetail extends Vue {
  isLoading = false;
  conditions = ["and", "or"];
  tabIndex = 0;
  operators = Object.entries(Operator).map(([key, value]) => {
    return { value, text: key };
  });

  @userFiltersNamespace.Getter("current")
  userFilter!: UserFilter;

  @userFiltersNamespace.Getter("availableFields")
  availableFields!: AvailableField[];

  @userFiltersNamespace.Mutation("SET_CURRENT")
  setUserFilter!: (filter: UserFilter) => void;

  @userFiltersNamespace.Action("fetchById")
  fetchById!: (id: string) => Promise<UserFilter>;

  @userFiltersNamespace.Action("fetchAvailableFields")
  fetchAvailableFields!: () => Promise<AvailableField[]>;

  @userFiltersNamespace.Action("create")
  create!: (filter: UserFilter) => Promise<UserFilter>;

  @userFiltersNamespace.Action("update")
  update!: (filter: UserFilter) => Promise<UserFilter>;

  get filterId() {
    return this.userFilter.id;
  }

  get isCreate() {
    return Boolean(this.filterId);
  }

  get locale() {
    return this.$i18n.locale;
  }

  addFilter() {
    this.userFilter.filter.push(new FilterOperation("", ""));
  }

  deleteFilterOperation(index: number) {
    this.userFilter.filter.splice(index, 1);
  }

  async doExport() {
    const response = await UserFilterService.export(
      this.userFilter.id,
      this.locale
    );
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `users-${this.userFilter.name}.xlsx`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async save() {
    this.isLoading = true;

    if (this.$route.params.id) {
      await this.update(this.userFilter);
    } else {
      await this.create(this.userFilter);
      this.$router.push({
        name: "user-filters-detail",
        params: { id: this.userFilter.id },
      });
    }

    this.$root.$bvToast.toast("User filter saved!", {
      variant: "success",
    });

    this.isLoading = false;
  }

  async created() {
    this.isLoading = true;
    await this.fetchAvailableFields();
    if (this.$route.params.id) {
      await this.fetchById(this.$route.params.id);
      if (this.$route.query.tabIndex) {
        this.tabIndex = parseInt(this.$route.query.tabIndex as string);
      }
    } else {
      this.setUserFilter(new UserFilter());
    }
    this.isLoading = false;
  }
}
