






































import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuetable from "vuetable-2";

import * as _ from "lodash";

const userFiltersNamespace = namespace("UserFilters");

@Component({ components: { Vuetable } })
export default class UserFiltersUsers extends Vue {
  isLoading = false;
  users: User[] = [];
  fields = [
    { name: "first_name", sortField: "first_name" },
    { name: "last_name", sortField: "last_name" },
    { name: "email", sortField: "email" },
    { name: "role", sortField: "role" },
    { name: "referral_token", sortField: "referral_token" },
    { name: "has_paid", sortField: "has_paid" },
    { name: "latest_login_date", sortField: "latest_login_date" },
    { name: "actions", sortField: "actions" },
  ];

  @Prop()
  filter!: string;

  @userFiltersNamespace.Getter("count")
  userFilterResult!: number;

  @userFiltersNamespace.Action("fetchUsersForFilter")
  fetchUsersForFilter!: (id: string) => Promise<User[]>;

  @userFiltersNamespace.Action("fetchCount")
  fetchCount!: (id: string) => Promise<User[]>;

  editUser(user) {
    this.$router.push({ name: "user-detail", query: { id: user.id } });
  }

  dataManager(sortOrder, pagination) {
    if (this.users.length < 1) return;

    let local = this.users;

    // sortOrder can be empty, so we have to check for that as well
    if (sortOrder.length > 0) {
      local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
    }

    return {
      pagination: pagination,
      data: local,
    };
  }

  async deleteUser(user) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${user.email}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.$store.dispatch("Users/delete", user.id);
      return this.fetchUsersForFilter(this.filter);
    }
    this.isLoading = false;
  }

  async created() {
    this.isLoading = true;
    this.users = await this.fetchUsersForFilter(this.filter);
    try {
      await this.fetchCount(this.filter);
    } catch (e) {
      this.$root.$bvToast.toast(`User sum error ${e}`, {
        variant: "danger",
      });
    }
    this.isLoading = false;
  }
}
